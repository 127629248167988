.alert.alert-primary,
.clientAlertModal{
    .modal-content{
        .close{
            &.close-oncontent{
                padding: var(--modal-btn-close-padding);
                z-index: 1;
                right: 28px;
                top: 16px;
        
                >span {
                    padding-bottom: 4px;
                }
    
                i {
                    font-size: 14px!important;
                }
            }
        }
    }
    .modal-title{
        padding-right: var(--spacing-4x); 
    }
    .btn{
        &.btn-link{
            background: transparent;
            border-color: transparent;
            &:hover{
                background: transparent;
                border-color: transparent;
            }
            &.btn-secondary{color: var(--btn-primary-faded-color); &:hover{color: var(--btn-primary-faded-hover-color);}}
            &.btn-default{color: var(--btn-default-color); &:hover{color: var(--btn-default-hover-color);}}
            &.btn-info{color: var(--btn-info-color); &:hover{color: var(--btn-info-hover-color);}}
            &.btn-success{color: var(--btn-success-color); &:hover{color: var(--btn-success-hover-color);}}
            &.btn-danger{color: var(--btn-danger-color); &:hover{color: var(--btn-danger-hover-color);}}
            &.btn-warning{color: var(--btn-warning-color); &:hover{color: var(--btn-warning-hover-color);}}
        }
    }
    .modal-footer{
        flex-wrap: wrap-reverse;
        .alert-buttons{
            padding-left: 0;
            > .btn{
                &:not(:last-of-type){
                    margin-right: var(--modal-footer-margin-between);
                    margin-left: 0;
                }
                
                
               
            }
        }
    }
}

.alert-primary{
    background: var(--ui-block-bg);
    background-color: unset!important;
    .alert-body{
        p{
            color: var(--text-body-color)!important;
        }
    }
    @media (max-width: 767px){
        .alert-body{
            align-self: auto;
        }
    }
}
.app-main{
    > .main-body{
        > .container{
            .custom-alerts{
                .clientAlert{
                    >.container{
                        display: flex;
                        padding-left: 0!important;
                        padding-right: 0!important;
                    }
                }
                .alert{
                    &:not(:last-child){
                        margin-bottom: 0;
                    }
                    & + .alert{
                        &:not(:first-child){
                            margin-top: var(--spacing-2x);
                        }
                    }
                }
            }
        }
    }
}

.login-body{
    > .custom-alerts{
        .clientAlert{
            > .container{
                display: flex;
                flex-direction: row;
                padding-left: 0!important; 
                padding-right: 0!important; 
            }
        }
    }
}
.login{
    &:not(.login-lg){
        .custom-alerts{
            .clientAlert{
                > .container{
                    .alert-content{
                        flex-direction: column;
                        align-self: flex-start;
                        .alert-body{
                            align-self: flex-start;
                        }
                        .alert-actions{
                            width: 100%;
                            padding: var(--spacing-1x) 0 0 0;
                            .btn{
                                margin-top: var(--spacing-1x);
                            }
                        }
                    }
                }
            }
        }
    }
}


//Alerts site
.app-main{
    > .custom-alerts{
        .alert-primary{
            margin-bottom: 0;
            border-radius: 0;
            box-shadow: none;
            padding-left: 0;
            padding-right: 0;
            border-bottom: 1px solid var(--ui-block-divider-color);
            > .container{
                display: flex;
            }
            &.alert-dismissible{
                padding-right: 0!important;
                @media (max-width: 767px){
                    .alert-content{
                        padding-right: var(--layout-container-gutter);
                    }
                }
            }
            &:before{
                display: none;
            }
        }
    }
    > .email-verification{
        padding-left: 0;
        padding-right: 0;
    }
}

